<template>
    <div>
        <page-title :heading="$t('erp.lang_SizeVariation') " :subheading="$t('erp.lang_SizeVariation')" :icon=icon></page-title>
        <div class="app-main__inner">
        <size-variations></size-variations>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import SizeVariations from "../../../components/erp/settings/SizeVariations";

    export default {
        components: {
            PageTitle,
            SizeVariations
        },

        data: () => ({
            icon: 'pe-7s-edit icon-gradient bg-tempting-azure',
        })
    }
</script>